import './App.css';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Drawer from '@mui/material/Drawer';

const INSTAGRAM_URL =
  'https://www.instagram.com/muscle_muse_/profilecard/?igsh=eTc2cjR0ZjIxNmN2';
const YOUTUBE_URL = 'https://youtube.com/@musclemuse777?feature=shared';
const TIKTOK_URL = 'https://www.tiktok.com/@muscle_muse_?_t=8rLm3YD2e4m&_r=1';
// const GOOGLE_PLAY_URL = ''
// const APP_STORE_URL=''

function App() {
  const [os, setOs] = useState('');

  const [isMenu, setIsMenu] = useState(false);
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [hoverStep, setHoverStep] = useState(0);
  const [position, setPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const { ref, inView } = useInView({
    // triggerOnce: false, // 영역에 한번만 진입했을 때 애니메이션 실행
    threshold: 0.5, // 뷰포트에서 50%가 보일 때 애니메이션 시작
  });

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.scrollY;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position]);

  const handleMouseOver = (step) => {
    setHoverStep(step);
  };

  const handleMouseOut = () => {
    setHoverStep(0);
  };

  const moveTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const moveToElement = (element) => {
    document.getElementById(element)?.scrollIntoView({ behavior: 'smooth' });
    setIsMenu(false);
  };

  const onClickLink = (type) => {
    if (type === 'googlePlay') {
      alert('준비중 입니다');
      if (os === 'Windows' || os === 'Android') {
        // 링크에 구글플레이 링크
        // window.open(GOOGLE_PLAY_URL)
        console.log(`접속중인 환경: ${os}`);
      }
      if (os === 'MacOS' || os === 'iOS') {
        // 링크에 앱스토어 링크
        // window.open(APP_STORE_URL)
        console.log(`접속중인 환경: ${os}`);
      }
    } else if (type === 'appStore') {
      alert('준비중 입니다');
      if (os === 'Windows' || os === 'Android') {
        // 링크에 구글플레이 링크
        // window.open(GOOGLE_PLAY_URL)
        console.log(`접속중인 환경: ${os}`);
      }
      if (os === 'MacOS' || os === 'iOS') {
        // 링크에 앱스토어 링크
        // window.open(APP_STORE_URL)
        console.log(`접속중인 환경: ${os}`);
      }
    }
    //  else if (type === 'fitX') {
    //   alert('준비중 입니다');
    // }
    else if (type === 'instagram') {
      window.open(INSTAGRAM_URL);
    } else if (type === 'youtube') {
      window.open(YOUTUBE_URL);
    } else if (type === 'tiktok') {
      window.open(TIKTOK_URL);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // 운영 체제 정보 확인
    if (userAgent.includes('Win')) {
      setOs('Windows');
    } else if (userAgent.includes('Mac')) {
      setOs('MacOS');
    } else if (userAgent.includes('Linux')) {
      setOs('Linux');
    } else if (userAgent.includes('Android')) {
      setOs('Android');
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      setOs('iOS');
    }
  }, []);

  return (
    <div className="App flex justify-center max-lg:overflow-x-hidden">
      <div className="flex flex-col max-w-[1920px] w-full">
        {/* header */}
        <div
          className={
            'h-[141px] max-lg:h-[47px] flex items-center sticky max-lg:fixed top-0 left-0 right-0 bg-bgBlack z-50 duration-200' +
            (visible ? ' opacity-100 z-50' : ' opacity-0 -z-50')
          }
        >
          <img
            src="/assets/images/logo_header.svg"
            className="w-[140px] max-lg:w-[71px] ml-[235px] max-lg:ml-5 cursor-pointer"
            alt="header-logo"
            onClick={moveTop}
          />
          {isMenu && (
            <div className="absolute right-[393px] top-0 h-[141px] flex items-center text-[30px] gap-x-10 max-lg:hidden">
              <span
                className="cursor-pointer"
                onClick={() => moveToElement('player')}
              >
                선수
              </span>
              <span
                className="cursor-pointer"
                onClick={() => moveToElement('coach')}
              >
                코치
              </span>
            </div>
          )}
          <Drawer
            open={isSideMenu}
            onClose={() => setIsSideMenu(false)}
            anchor="right"
            className="lg:hidden"
          >
            <div
              className="w-[180px] bg-black h-screen relative"
              role="presentation"
              onClick={() => setIsSideMenu(false)}
            >
              <motion.img
                src="/assets/icons/icon_close.svg"
                className="max-lg:w-[13px] cursor-pointer absolute top-[30px] right-5"
                alt="icon_close"
                onClick={() => setIsSideMenu(false)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.8 }}
              />
              <div className="flex flex-col mt-20 gap-y-5">
                {['선수', '코치'].map((text, index) => (
                  <div
                    key={index}
                    className="text-[24px] text-white ml-[22px]"
                    onClick={() =>
                      moveToElement(text === '코치' ? 'coach' : 'player')
                    }
                  >
                    {text}
                  </div>
                ))}
              </div>
            </div>
          </Drawer>
          <motion.img
            src="/assets/icons/icon_menu.svg"
            className="w-[55px] max-lg:w-6 ml-auto mr-[235px] max-lg:mr-5 cursor-pointer max-lg:hidden"
            alt="icon-icon_menu"
            onClick={() => setIsMenu(!isMenu)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
          />
          <motion.img
            src="/assets/icons/icon_menu.svg"
            className="w-[55px] max-lg:w-6 ml-auto mr-[235px] max-lg:mr-5 cursor-pointer lg:hidden"
            alt="icon-icon_menu"
            onClick={() => setIsSideMenu(!isSideMenu)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
          />
        </div>
        {/* section1 */}
        <section className="flex flex-col relative lg:h-[1400px] lg:overflow-hidden ">
          {/* <div className="w-screen bg-cover bg-[url('../assets/images/bg_section1.jpeg)]  "></div> */}
          <img
            src="/assets/images/bg_section1.png"
            className="w-full absolute top-[326px] -z-10 max-sm:hidden"
            alt="bg-section1"
          />
          <img
            src="/assets/images/bg_section1_sm.png"
            className="w-full mt-[188px] -z-10 lg:hidden"
            alt="bg-bg_section1_sm"
          />
          <div className="mt-[100px] text-[130px] max-lg:text-[40px] font-bold text-center lg:leading-[160px] max-lg:tracking-[-1.6px] relative max-lg:absolute max-lg:text-center max-lg:w-full">
            파워리프팅의 세계를
            <br />
            <span className="text-primary">더 많은 사람들에게</span>
            <div className="bg-bgBlack h-4 max-lg:h-1.5 w-full lg:animate-fadein max-lg:animate-fadeinMd absolute top-[76px] max-lg:top-[24px]"></div>
          </div>
          <div className="mt-6 text-[30px] max-lg:text-base font-medium text-center max-lg:absolute max-lg:mt-[230px] max-lg:text-center max-lg:w-full">
            회원가입하고, 4주 무료체험을 진행해보세요
          </div>
          <div className="mt-8 flex items-center justify-center gap-x-5 max-lg:flex-col max-lg:gap-y-2.5 max-lg:absolute max-lg:top-[280px] max-lg:w-full">
            <div
              className="bg-bgBlack rounded-md w-[220px] max-lg:w-[200px] h-[89px] max-lg:h-[74px] flex items-center justify-center gap-x-2.5 cursor-pointer"
              onClick={() => onClickLink('googlePlay')}
            >
              <img
                src="/assets/icons/icon_google_play.svg"
                className="w-[50px] max-lg:w-[44px]"
                alt="icon_google_play"
              />
              <div className="flex flex-col">
                <span className="font-medium max-lg:text-xs">Get it on</span>
                <span className="text-xl max-lg:text-base font-medium">
                  Google Play
                </span>
              </div>
            </div>
            <div
              className="bg-bgBlack rounded-md w-[220px] max-lg:w-[200px] h-[89px] max-lg:h-[74px] flex items-center justify-center gap-x-2.5 cursor-pointer"
              onClick={() => onClickLink('appStore')}
            >
              <img
                src="/assets/icons/icon_app_store.svg"
                className="w-[50px] max-lg:w-[44px]"
                alt="icon_app_store"
              />
              <div className="flex flex-col">
                <span className="font-medium max-lg:text-xs">
                  Download on the
                </span>
                <span className="text-xl font-medium max-lg:text-base">
                  App Store
                </span>
              </div>
            </div>
          </div>
        </section>
        {/* section2 */}
        <section className="flex flex-col lg:h-[616px] lg:justify-center">
          <div className="w-full flex max-lg:flex-col justify-center items-center max-lg:my-20">
            <div className="flex flex-col max-lg:items-end">
              <img
                src="/assets/images/logo_fit_tech_dynamics.png"
                className="lg:h-[142px] max-lg:h-[50px]"
                alt="logo_fit_tech_dynamics"
              />
              <p className="text-[130px] max-lg:text-[70px] font-extrabold leading-[130px] text-right">
                WITH
              </p>
            </div>

            <motion.img
              src="/assets/icons/icon_cross.svg"
              className="w-[160px] h-[160px] mt-20 max-lg:mt-[-20px] max-lg:ml-auto max-lg:mr-5"
              alt="icon_cross"
              initial={{ rotate: 45 }}
              whileInView={{ rotate: 180 }}
              transition={{ duration: 1 }}
            />
            <motion.img
              src="/assets/images/logo_usa_power_lifting.png"
              className="h-[180px] max-lg:h-[150px] mt-20 max-lg:mt-[-20px]"
              alt="logo_usa_power_lifting"
              initial={{ opacity: 1, x: -150 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </section>
        {/* section3 */}
        <section className="flex flex-col relative lg:h-[908px] max-lg:h-auto">
          <div className="flex flex-col items-end max-lg:items-center">
            <img
              src="/assets/images/image_section3_phone.png"
              className="lg:h-[668px] lg:absolute lg:top-5 lg:left-28 max-lg:block max-lg:order-1 max-lg:w-[80%] max-lg:text-center max-lg:my-12"
              alt="image_section2_phone"
            />
            <div className="flex justify-end max-lg:order-1">
              <div className="lg:h-[454px] lg:w-[454px] bg-[#282828] max-lg:w-screen max-lg:aspect-square">
                <motion.div
                  className="w-full lg:h-full max-lg:aspect-square rounded-full bg-bgBlack flex flex-col items-center justify-center text-[30px] max-lg:text-xl font-bold leading-[40px] text-center"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.8,
                  }}
                >
                  <div className="flex items-center">
                    <div className="flex justify-center flex-row-reverse">
                      {Array.from('드월').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                      &nbsp;
                      {Array.from('해통').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: 0.2 + index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                      &nbsp;
                      {Array.from('를XtiF').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: 0.4 + index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                    </div>
                    <div className="flex justify-center">
                      {Array.from('클래스').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                      &nbsp;
                      {Array.from('코치와').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: 0.3 + index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex justify-center flex-row-reverse">
                      &nbsp;
                      {Array.from('인적계체').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                      &nbsp;
                    </div>
                    <div className="flex justify-center">
                      {Array.from('관리를').map((letter, index) => (
                        <motion.span
                          key={index}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            delay: index * 0.1,
                            duration: 0.5,
                          }}
                        >
                          {letter}
                        </motion.span>
                      ))}
                    </div>
                  </div>
                </motion.div>
              </div>
              <img
                src="/assets/images/image_section3_people.png"
                className="h-[454px] max-lg:hidden"
                alt="image_section3_people"
              />
            </div>
            <img
              src="/assets/images/image_section3_people_sm.png"
              className="lg:hidden max-lg:block max-lg:w-full max-lg:order-1"
              alt="image_section3_people_sm"
            />
            <div className="mr-[319px] max-lg:mr-0 max-lg:w-full max-lg:aspect-square lg:h-[454px] lg:w-[454px] bg-[#282828]">
              <motion.div
                className="w-full h-full rounded-full bg-bgBlack flex flex-col items-center justify-center text-[30px] max-lg:text-xl font-bold leading-[40px] text-center"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{
                  duration: 0.8,
                }}
              >
                <div className="flex items-center">
                  <div className="flex justify-center flex-row-reverse">
                    &nbsp;
                    {Array.from('스래클드월').map((letter, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                          delay: index * 0.1,
                          duration: 0.5,
                        }}
                      >
                        {letter}
                      </motion.span>
                    ))}
                    &nbsp;
                  </div>
                  <div className="flex justify-center">
                    {Array.from('코치와').map((letter, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                          delay: index * 0.1,
                          duration: 0.5,
                        }}
                      >
                        {letter}
                      </motion.span>
                    ))}
                    &nbsp;
                    {Array.from('함께').map((letter, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                          delay: 0.3 + index * 0.1,
                          duration: 0.5,
                        }}
                      >
                        {letter}
                      </motion.span>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex justify-center flex-row-reverse">
                    &nbsp;
                    {Array.from('는하장성').map((letter, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                          delay: index * 0.1,
                          duration: 0.5,
                        }}
                      >
                        {letter}
                      </motion.span>
                    ))}
                    &nbsp;
                  </div>
                  <div className="flex justify-center">
                    {Array.from('파워리프팅').map((letter, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{
                          delay: index * 0.1,
                          duration: 0.5,
                        }}
                      >
                        {letter}
                      </motion.span>
                    ))}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>
        {/* section4 */}
        <section className="flex relative h-[1252px] max-lg:h-auto max-lg:flex-col">
          <div className="lg:ml-[235px] lg:h-[454px] lg:min-w-[454px] bg-[#282828] max-lg:w-screen max-lg:aspect-square">
            <motion.div
              className="max-lg:w-full lg:h-full max-lg:aspect-square rounded-full bg-bgBlack flex flex-col items-center justify-center text-[30px] max-lg:text-xl font-bold leading-[40px] text-center"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 0.8,
              }}
            >
              <div className="flex items-center">
                <div className="flex justify-center flex-row-reverse">
                  &nbsp;
                  {Array.from('상영').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                  &nbsp;
                  {Array.from('IA').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: 0.2 + index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex justify-center">
                  {Array.from('분석으로').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex justify-center flex-row-reverse">
                  {Array.from('세').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                  &nbsp;
                  {Array.from('고하확정').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: 0.1 + index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                  &nbsp;
                </div>
                <div className="flex justify-center">
                  {Array.from('밀한').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                  &nbsp;
                  {Array.from('자세').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                  &nbsp;
                  {Array.from('교정').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                  &nbsp;
                </div>
              </div>
            </motion.div>
          </div>
          <div className="h-[918px] max-lg:h-auto max-lg:pb-[170px] bg-[#141414] w-full relative">
            <img
              src="/assets/images/image_section4_phone.png"
              className="lg:h-[550px] max-lg:h-auto max-lg:w-[40%] max-lg:ml-6 max-lg:mt-16 lg:absolute top-16 left-[122px]"
              alt="image_section4_phone"
            />
            <img
              src="/assets/images/image_section4_tab.png"
              className="lg:h-[430px] max-lg:h-[202px] max-lg:ml-auto max-lg:mt-5 lg:absolute top-[197px] right-[73px]"
              alt="image_section4_tab"
            />
            {/* <motion.div className="w-[454px] h-[454px] flex items-center cursor-pointer justify-center text-[45px] font-bold absolute bottom-[-227px] right-[440px] z-20 bg-primary rounded-full hover:text-primary hover:bg-[#282828] transition ease-in-out delay-400 duration-500"> */}
            <motion.div
              className="w-[454px] h-[454px] max-lg:w-[260px] max-lg:h-[260px] flex items-center cursor-pointer justify-center text-[45px] max-lg:text-xl font-bold absolute  bottom-[-227px] right-[440px] max-lg:bottom-[-130px] max-lg:right-0 max-lg:left-0 z-20 bg-primary rounded-full max-lg:mx-auto "
              whileTap={{
                scale: 0.5,
                rotate: 180,
                transition: { duration: 0.3, ease: 'easeInOut' },
              }}
              initial={{ scale: 1, rotate: 0 }}
            >
              FitX 시작하기
            </motion.div>
          </div>
        </section>
        {/* section5 */}
        <section className="flex flex-col relative w-full min-h-[1080px] max-lg:h-auto overflow-hidden">
          <div className="mt-[128px] max-lg:mt-[194px] relative flex overflow-x-hidden items-center justify-center">
            <div className="animate-infinite-slider whitespace-nowrap">
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                <span className="text-primary">데이터</span>로 확인하는 나의
                퍼포먼스 변화
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                <span className="text-primary">데이터</span>로 확인하는 나의
                퍼포먼스 변화
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                <span className="text-primary">데이터</span>로 확인하는 나의
                퍼포먼스 변화
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                <span className="text-primary">데이터</span>로 확인하는 나의
                퍼포먼스 변화
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                <span className="text-primary">데이터</span>로 확인하는 나의
                퍼포먼스 변화
              </span>
            </div>
          </div>
          <img
            src="/assets/images/image_section5_two_phone.png"
            className="lg:h-[603px] max-lg:w-[100%] max-lg:mt-6 max-lg:px-5 lg:absolute top-[88px] right-[363px] z-10"
            alt="image_section5_two_phone"
          />
          <img
            src="/assets/images/image_section5_three_phone.png"
            className="lg:h-[548px] max-lg:w-[100%] max-lg:mt-6 max-lg:px-5 lg:absolute top-[444px] left-[235px] z-10"
            alt="image_section5_three_phone"
          />

          <div className="mt-[574px] max-lg:mt-[54px] max-lg:mb-[100px] relative flex overflow-x-hidden items-center justify-center">
            <div className="animate-infinite-slider-reverse whitespace-nowrap">
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                코칭과 대회 일정을{' '}
                <span className="text-primary">통합 관리</span>
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                코칭과 대회 일정을{' '}
                <span className="text-primary">통합 관리</span>
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                코칭과 대회 일정을{' '}
                <span className="text-primary">통합 관리</span>
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                코칭과 대회 일정을{' '}
                <span className="text-primary">통합 관리</span>
              </span>
              <span className="lg:text-[100px] max-lg:text-[50px] font-bold mr-2">
                코칭과 대회 일정을{' '}
                <span className="text-primary">통합 관리</span>
              </span>
            </div>
          </div>
        </section>
        {/* section6 */}
        <section
          id="player"
          className="flex flex-col relative w-full min-h-[1080px] overflow-hidden bg-cover lg:bg-[url('./images/bg_section6.png')]"
        >
          <div className="mt-24 lg:mb-12 max-lg:mb-9 max-lg:mt-12 flex items-center justify-center text-[100px] max-lg:text-[40px] font-bold">
            선수 구독 플랜
          </div>
          <div className="flex items-center lg:justify-between lg:gap-x-[55px] lg:px-20 max-lg:px-5 max-lg:flex-col max-lg:mt-5 max-lg:gap-y-5">
            <div className="flex flex-col w-full">
              <div className="flex h-[108px]">
                <div className="bg-black h-full w-full"></div>
                <div className="w-0 h-0 border-b-[108px] border-b-deepBlue border-r-[100px] border-r-transparent"></div>
              </div>
              <div className="max-lg:mt-[-1px] h-[523px] max-lg:h-[347px] w-full bg-black flex flex-col ">
                <div className=" h-[98px] max-lg:h-5 pl-10 max-lg:pl-5 flex items-end border-b-[0.1px] border-white/60 text-[50px] max-lg:text-[30px] font-bold">
                  {Array.from('BASIC').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex flex-col mt-11 max-lg:mt-8 pl-[50px] max-lg:pl-5 text-xl max-lg:text-base gap-y-2.5">
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    실시간 영상 코칭
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    코치의 훈련 프로그램 제공
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    체중/식단/운동 기록 (2주 보관)
                  </div>
                </div>
              </div>
              {/* <div className="h-[124px] max-lg:h-[115px] bg-deepBlue/80 flex items-center justify-center tracking-[-1.8px]">
                <span className="text-[45px] font-light max-lg:text-[30px]">
                  ₩
                </span>
                <span className="text-[45px] font-bold max-lg:text-[30px]">
                  8,900~/
                </span>
                <span className="text-[25px] mt-4 tracking-[-1px] max-lg:text-base">
                  month
                </span>
              </div> */}
            </div>
            <div className="flex flex-col w-full">
              <div className="flex h-[108px]">
                <div className="bg-black h-full w-full"></div>
                <div className="w-0 h-0 border-b-[108px] border-b-deepBlue border-r-[100px] border-r-transparent"></div>
              </div>
              <div className="max-lg:mt-[-1px] h-[523px] max-lg:h-[347px] w-full bg-black flex flex-col">
                <div className=" h-[98px] max-lg:h-5 pl-10 max-lg:pl-5 flex items-end border-b-[0.1px] border-white/60 text-[50px] max-lg:text-[30px] font-bold">
                  {Array.from('PRO').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex flex-col mt-11 max-lg:mt-8 pl-[50px] max-lg:pl-5 text-xl max-lg:text-base gap-y-2.5">
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    실시간 영상 코칭
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    코치의 훈련 프로그램 제공
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    체중/식단/운동 기록 (3개월 보관)
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    내 운동 영상 업로드
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    운동 자동 기록
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    달성 시 배지 획득
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    코치의 댓글/음성 피드백
                  </div>
                </div>
              </div>
              {/* <div className="h-[124px] max-lg:h-[115px] bg-deepBlue/80 flex items-center justify-center tracking-[-1.8px]">
                <span className="text-[45px] font-light max-lg:text-[30px]">
                  ₩
                </span>
                <span className="text-[45px] font-bold max-lg:text-[30px]">
                  13,900~/
                </span>
                <span className="text-[25px] mt-4 tracking-[-1px] max-lg:text-base">
                  month
                </span>
              </div> */}
            </div>
            <div className="flex flex-col w-full">
              <div className="flex h-[108px]">
                <div className="bg-white text-black h-full w-full"></div>
                <div className="w-0 h-0 border-b-[108px] border-b-deepBlue border-r-[100px] border-r-transparent"></div>
              </div>
              <div className="max-lg:mt-[-1px] h-[523px] max-lg:h-[347px] w-full bg-white text-black flex flex-col  relative">
                <div className="w-[54px] h-[38px] rounded-[50px] bg-primary text-lg flex items-center justify-center text-white font-bold absolute left-10 top-[-20px] max-lg:hidden">
                  추천
                </div>
                <div className="h-[98px] max-lg:h-5 pl-10 max-lg:pl-5 flex items-end border-b-[0.1px] border-black/60 text-[50px] max-lg:text-[30px] font-bold">
                  {Array.from('ELITE').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex flex-col mt-11 max-lg:mt-8 pl-[50px] max-lg:pl-5 text-xl max-lg:text-base gap-y-2.5">
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    실시간 영상 코칭
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    코치의 훈련 프로그램 제공
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    체중/식단/운동 기록 (10개월 보관)
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    내 운동 영상 업로드
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    운동 자동 기록
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    달성 시 배지 획득
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    코치의 댓글/음성 피드백
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_blue.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_blue"
                    />
                    광고 없음
                  </div>
                </div>
              </div>
              {/* <div className="h-[124px] max-lg:h-[115px] bg-deepBlue/80 flex items-center justify-center tracking-[-1.8px]">
                <span className="text-[45px] font-light max-lg:text-[30px]">
                  ₩
                </span>
                <span className="text-[45px] font-bold max-lg:text-[30px]">
                  19,800~/
                </span>
                <span className="text-[25px] mt-4 tracking-[-1px] max-lg:text-base">
                  month
                </span>
              </div> */}
            </div>
            <div className="flex flex-col w-full">
              <div className="flex h-[108px]">
                <div className="bg-primary h-full w-full"></div>
                <div className="w-0 h-0 border-b-[108px] border-b-white border-r-[100px] border-r-transparent"></div>
              </div>
              <div className="max-lg:mt-[-1px] h-[523px] max-lg:h-[347px] w-full bg-primary flex flex-col">
                <div className="h-[98px] max-lg:h-5 pl-10 max-lg:pl-5 flex items-end border-b-[0.1px] border-white/60 text-[50px] max-lg:text-[30px] font-bold">
                  {Array.from('ULTIMATE').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex flex-col mt-11 max-lg:mt-4 pl-[50px] max-lg:pl-5 text-xl max-lg:text-base gap-y-2.5">
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    실시간 영상 코칭
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    코치의 훈련 프로그램 제공
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    체중/식단/운동 기록 (3개월 보관)
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    내 운동 영상 업로드
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    운동 자동 기록
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    달성 시 배지 획득
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    코치의 댓글/음성 피드백
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    광고 없음
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/icons/icon_check_mint.svg"
                      className="h-6 w-6 mr-2.5"
                      alt="icon_check_mint"
                    />
                    AI 분석 영상 제공
                  </div>
                </div>
              </div>
              {/* <div className="h-[124px] max-lg:h-[115px] bg-deepBlue/80 flex items-center justify-center tracking-[-1.8px]">
                <span className="text-[45px] max-lg:text-[30px]">₩ &nbsp;</span>
                <span className="text-[45px] font-bold max-lg:text-[30px]">
                  27,900~/
                </span>
                <span className="text-[25px] mt-4 tracking-[-1px] max-lg:text-base">
                  month
                </span>
              </div> */}
            </div>
          </div>
        </section>
        {/* section7 */}
        {/* PC */}
        <section
          ref={ref}
          className="flex flex-col relative lg:h-[1758px] max-lg:h-auto overflow-hidden max-lg:hidden"
        >
          <div className="mt-[316px] max-lg:mt-8 flex flex-col justify-center items-center">
            <div className="text-[150px] max-lg:text-[80px] font-bold text-primary text-center">
              COACH
            </div>
            <img
              src="/assets/icons/icon_cross.svg"
              className="w-[224px] h-[224px] mt-[-60px]"
              alt="icon_cross"
            />
            <div className="text-[150px] max-lg:text-[80px] max-lg:mt-[-60px] font-bold text-center">
              FOR YOU
            </div>
          </div>

          <motion.div
            className="flex flex-col w-[500px] absolute top-[242px] left-[40%] z-10 max-lg:hidden"
            initial={{ opacity: 1, rotate: '-15.115deg' }}
            animate={{
              x: inView ? '-133%' : -40,
              y: inView ? '-180px' : 80,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[126px] text-[38px] font-bold flex items-center px-8 leading-[-1.52px]">
              숫자로 한눈에 보는 <br />
              선수 현황
            </div>
            <div className="h-[530px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_tl.png"
                className="h-[470px]"
                alt="image_section7_phone_tl"
              />
            </div>
          </motion.div>
          <motion.div
            className="flex flex-col w-[500px] absolute top-[166px] right-[40%] z-20 max-lg:hidden"
            initial={{ opacity: 1, rotate: '12.896deg' }}
            animate={{
              x: inView ? '133%' : 300,
              y: inView ? -22 : 100,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[126px] text-[38px] font-bold flex items-center px-8 leading-[-1.52px]">
              선수와 수업을 <br />
              손쉽게 연결
            </div>
            <div className="h-[530px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_tr.png"
                className="h-[470px]"
                alt="image_section7_phone_tr"
              />
            </div>
          </motion.div>
          <motion.div
            className="flex flex-col w-[500px] absolute bottom-[200px] left-[40%] z-20 max-lg:hidden"
            initial={{ opacity: 1, rotate: '9.3095deg' }}
            animate={{
              x: inView ? '-103%' : -160,
              y: inView ? 38 : -200,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[126px] text-[38px] font-bold flex items-center px-8 leading-[-1.52px]">
              손쉬운 <br />
              프로그램 구성
            </div>
            <div className="h-[530px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_bl.png"
                className="h-[470px]"
                alt="image_section7_phone_bl"
              />
            </div>
          </motion.div>
          <motion.div
            className="flex flex-col w-[500px] absolute bottom-[200px] right-[40%] z-30 max-lg:hidden"
            initial={{ opacity: 1, rotate: '-12.415deg' }}
            animate={{
              x: inView ? '113%' : 360,
              y: inView ? -22 : -242,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[126px] text-[38px] font-bold flex items-center px-8 leading-[-1.52px]">
              정산 내역을 한눈에, <br />더 쉬운 수익 관리
            </div>
            <div className="h-[530px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_br.png"
                className="h-[456px]"
                alt="image_section7_phone_br"
              />
            </div>
          </motion.div>
        </section>
        {/* mobile */}
        <section className="flex flex-col relative pb-[100px] lg:h-[1758px] max-lg:h-auto overflow-hidden lg:hidden">
          <motion.div
            className="flex flex-col mt-32 w-[245px] z-10 lg:hidden"
            initial={{ opacity: 1, x: 320, y: 120, rotate: '-15.115deg' }}
            whileInView={{
              x: '40%',
              y: 0,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[62px] text-[20px] font-bold flex items-center px-8 leading-[-1.52px]">
              숫자로 한 눈에 보는
              <br />
              선수 현황
            </div>
            <div className="h-[256px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_tl.png"
                className="h-[230px]"
                alt="image_section7_phone_tl"
              />
            </div>
          </motion.div>
          <motion.div
            className="flex flex-col mt-0 w-[245px] z-10 lg:hidden"
            initial={{ opacity: 1, x: -120, y: 120, rotate: '12.896deg' }}
            whileInView={{
              x: '25%',
              y: 0,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[62px] text-[20px] font-bold flex items-center px-8 leading-[-1.52px]">
              선수와 수업을 <br />
              손쉽게 연결
            </div>
            <div className="h-[256px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_tr.png"
                className="h-[230px]"
                alt="image_section7_phone_tr"
              />
            </div>
          </motion.div>
          <div className="mt-[316px] max-lg:mt-8 flex flex-col justify-center items-center">
            <div className="text-[150px] max-lg:text-[80px] font-bold text-primary text-center">
              COACH
            </div>
            <img
              src="/assets/icons/icon_cross.svg"
              className="w-[224px] h-[224px] mt-[-60px]"
              alt="icon_cross"
            />
            <div className="text-[150px] max-lg:text-[80px] max-lg:mt-[-60px] font-bold text-center">
              FOR YOU
            </div>
          </div>
          <motion.div
            className="flex flex-col mt-12 w-[245px] z-10 lg:hidden"
            initial={{ opacity: 1, x: -120, y: 120, rotate: '9.3095deg' }}
            whileInView={{
              x: '45%',
              y: 0,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[62px] text-[20px] font-bold flex items-center px-8 leading-[-1.52px]">
              손쉬운 <br />
              프로그램 구성
            </div>
            <div className="h-[256px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_bl.png"
                className="h-[230px]"
                alt="image_section7_phone_bl"
              />
            </div>
          </motion.div>
          <motion.div
            className="flex flex-col mt-5 w-[245px] z-10 lg:hidden"
            initial={{ opacity: 1, x: 320, y: 120, rotate: '-12.415deg' }}
            whileInView={{
              x: 80,
              y: 0,
            }}
            transition={{ duration: 1 }}
          >
            <div className="bg-black h-[62px] text-[20px] font-bold flex items-center px-8 leading-[-1.52px]">
              정산 내역을 한눈에, <br />더 쉬운 수익 관리
            </div>
            <div className="h-[256px] bg-[#2B2B2B] flex items-center justify-center">
              <img
                src="/assets/images/image_section7_phone_br.png"
                className="h-[230px]"
                alt="image_section7_phone_br"
              />
            </div>
          </motion.div>
        </section>
        {/* section8 */}
        <section
          id="coach"
          className="flex flex-col relative w-full min-w-full lg:min-h-[1080px] max-lg:h-auto bg-black max-lg:pb-[130px]"
        >
          <div className="mt-[190px] max-lg:mt-[50px] flex items-center max-lg:flex-col justify-between px-[235px] max-lg:px-5">
            <div className="text-[120px] max-lg:text-[40px] font-bold leading-[-4.8px]">
              코치플랜
            </div>
            {/* <div className="text-[60px] font-light max-lg:text-[24px]">
              [
              <span className="pl-20 max-lg:pl-8 text-[30px] max-lg:text-base">
                선수 1명당
              </span>
              <span className="font-bold"> ₩5,000~</span>
              <span className="font-medium">/</span>
              <span className="pr-20 max-lg:pr-8 text-[30px] max-lg:text-base">
                month
              </span>{' '}
              ]
            </div> */}
          </div>
          <div className="lg:mt-[86px] lg:pl-[235px] lg:pr-10 flex max-lg:flex-col max-lg:px-5">
            <div
              className={
                'text-[120px] max-lg:text-[70px] max-lg:mt-[47px] font-bold -tracking-[4.8px] leading-[100px] max-lg:leading-[100%] transition-all max-lg:order-1' +
                (hoverStep !== 0 ? ' text-primary' : ' text-[#202020]')
              }
            >
              REDEFINE
              <br />
              YOUR
              <br />
              LIMITS
            </div>
            <div className="ml-20 max-lg:ml-0 flex flex-col w-full max-lg:mt-5">
              <div
                className="px-2.5 py-5 text-[45px] max-md:text-[30px] font-bold border-b border-white hover:bg-primary lg:hover:pl-10 hover:transition-all hover:border-primary flex items-center "
                onMouseOver={() => handleMouseOver(1)}
                onMouseOut={handleMouseOut}
              >
                온라인 코칭 관리
                {/* {hoverStep === 1 && ( */}
                <span
                  className={
                    'ml-auto text-sm font-normal tracking-tighter duration-500 max-lg:hidden' +
                    (hoverStep === 1 ? ' opacity-100' : ' opacity-0')
                  }
                >
                  맞춤형 프로그램 구성과 피드백을 한 곳에서 편리한 코칭을
                  제공합니다.
                </span>
                {/* )} */}
              </div>
              <div
                className="px-2.5 py-5 text-[45px] max-md:text-[30px] font-bold border-b border-white hover:bg-primary lg:hover:pl-10 hover:transition-all hover:border-primary flex items-center "
                onMouseOver={() => handleMouseOver(2)}
                onMouseOut={handleMouseOut}
              >
                대면 코칭 관리
                <span
                  className={
                    'ml-auto text-sm font-normal tracking-tighter duration-500 max-lg:hidden' +
                    (hoverStep === 2 ? ' opacity-100' : ' opacity-0')
                  }
                >
                  대면 코칭 일정을 체계적으로 관리하세요.
                </span>
              </div>
              <div
                className="px-2.5 py-5 text-[45px] max-md:text-[30px] font-bold border-b border-white hover:bg-primary lg:hover:pl-10 hover:transition-all hover:border-primary flex items-center "
                onMouseOver={() => handleMouseOver(3)}
                onMouseOut={handleMouseOut}
              >
                대회 선수 관리
                <span
                  className={
                    'ml-auto text-sm font-normal tracking-tighter duration-500 max-lg:hidden' +
                    (hoverStep === 3 ? ' opacity-100' : ' opacity-0')
                  }
                >
                  대회 준비부터 성과까지 선수 관리를 지원합니다.
                </span>
              </div>
              <div
                className="px-2.5 py-5 text-[45px] max-md:text-[30px] font-bold border-b border-white hover:bg-primary lg:hover:pl-10 hover:transition-all hover:border-primary flex items-center "
                onMouseOver={() => handleMouseOver(4)}
                onMouseOut={handleMouseOut}
              >
                스케줄 및 수익 관리
                <span
                  className={
                    'ml-auto text-sm font-normal tracking-tighter duration-500 max-lg:hidden' +
                    (hoverStep === 4 ? ' opacity-100 ' : ' opacity-0')
                  }
                >
                  일정과 수익 현황을 실시간 파악하여 업무 효율을 극대화하세요.
                </span>
              </div>
            </div>
            <div
              className={
                'mt-2.5 h-5 text-sm lg:hidden max-lg:block duration-500' +
                (hoverStep !== 0 ? ' opacity-100' : ' opacity-0')
              }
            >
              {hoverStep === 1
                ? '맞춤형 프로그램 구성과 피드백을 한 곳에서 편리한 코칭을 제공합니다.'
                : hoverStep === 2
                  ? '대면 코칭 일정을 체계적으로 관리하세요.'
                  : hoverStep === 3
                    ? '대회 준비부터 성과까지 선수 관리를 지원합니다.'
                    : hoverStep === 4
                      ? '일정과 수익 현황을 실시간 파악하여 업무 효율을 극대화하세요.'
                      : ''}
            </div>
          </div>
        </section>
        {/* section9 */}
        <section className="flex flex-col relative w-full lg:min-h-[1080px] max-lg:min-h-[780px] max-lg:pb-60 overflow-hidden bg-cover lg:bg-[url('./images/bg_section9.png')] max-lg:bg-[url('./images/bg_section9_sm.png')] items-center">
          <div className="mt-[100px] max-lg:mt-[50px] text-center text-[100px] max-lg:text-[40px] font-bold tracking-tighter flex flex-col">
            <div className="flex items-center justify-center">
              <div className="flex justify-center flex-row-reverse">
                {Array.from('5202').map((letter, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: index * 0.1,
                      duration: 0.5,
                    }}
                  >
                    {letter}
                  </motion.span>
                ))}
              </div>
              <div className="flex justify-center">
                &nbsp;
                {Array.from('USAPL').map((letter, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: index * 0.1,
                      duration: 0.5,
                    }}
                  >
                    {letter}
                  </motion.span>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center max-lg:flex-col">
              <div className="flex justify-center flex-row-reverse max-lg:hidden">
                {Array.from('mahC').map((letter, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: index * 0.1,
                      duration: 0.5,
                    }}
                  >
                    {letter}
                  </motion.span>
                ))}
                &nbsp;
                {Array.from('dlroW').map((letter, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: 0.5 + index * 0.1,
                      duration: 0.5,
                    }}
                  >
                    {letter}
                  </motion.span>
                ))}
              </div>
              <div className="flex justify-center max-lg:hidden">
                {Array.from('pionship').map((letter, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: index * 0.1,
                      duration: 0.5,
                    }}
                  >
                    {letter}
                  </motion.span>
                ))}
              </div>

              <div className="flex items-center justify-center lg:hidden">
                <div className="flex justify-center flex-row-reverse">
                  {Array.from('oW').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex justify-center">
                  {Array.from('rld').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-center lg:hidden">
                <div className="flex justify-center flex-row-reverse">
                  {Array.from('ipmahC').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
                <div className="flex justify-center">
                  {Array.from('onship').map((letter, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.1,
                        duration: 0.5,
                      }}
                    >
                      {letter}
                    </motion.span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <div className="mt-10 flex items-start flex-row-reverse">
              {Array.from('ten').map((letter, index) => (
                <motion.img
                  key={index}
                  src={`/assets/images/netflix/${letter}.png`}
                  className="h-[176px] max-lg:h-[72px]"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    delay: 0.2 + index * 0.1,
                    duration: 0.2,
                  }}
                />
              ))}
            </div>
            <div className="mt-10 flex items-start">
              {Array.from('flix').map((letter, index) => (
                <motion.img
                  key={index}
                  src={`/assets/images/netflix/${letter}.png`}
                  className="h-[176px] max-lg:h-[72px]"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    delay: 0.2 + index * 0.1,
                    duration: 0.2,
                  }}
                />
              ))}
            </div>
          </div>
          <motion.div
            className="mt-16 rounded-[10px] bg-white w-[350px] h-[60px] max-lg:h-[56px] max-lg:w-[260px] flex items-center justify-center"
            initial={{ opacity: 0.1 }}
            whileInView={{
              opacity: 1,
            }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center">
              <div className="flex items-start flex-row-reverse">
                {Array.from('apuoc').map((letter, index) => (
                  <motion.img
                    key={index}
                    src={`/assets/images/coupang-play/${letter}.png`}
                    className="h-[50px] max-lg:h-9"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: 0.2 + index * 0.1,
                      duration: 0.2,
                    }}
                  />
                ))}
                <motion.img
                  src={`/assets/images/coupang-play/icon.png`}
                  className="h-[50px] max-lg:h-9"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    delay: 0.7,
                    duration: 0.2,
                  }}
                />
              </div>
              <div className="flex items-start">
                {Array.from('ngplay').map((letter, index) => (
                  <motion.img
                    key={index}
                    src={`/assets/images/coupang-play/${letter}.png`}
                    className="h-[50px] max-lg:h-9"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: 0.2 + index * 0.1,
                      duration: 0.2,
                    }}
                  />
                ))}
              </div>
            </div>
          </motion.div>
          <div className="mt-[110px] max-lg:mt-[100px] text-[50px] max-lg:text-[30px] text-white/50 font-bold flex">
            <div className="flex justify-center flex-row-reverse">
              {Array.from('gnimoC').map((letter, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    delay: index * 0.1,
                    duration: 0.2,
                  }}
                >
                  {letter}
                </motion.span>
              ))}
            </div>
            <div className="flex justify-center ml-2">
              {Array.from('soon').map((letter, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    delay: index * 0.1,
                    duration: 0.2,
                  }}
                >
                  {letter}
                </motion.span>
              ))}
            </div>
          </div>
        </section>
        {/* footer */}
        <section className="h-[410px] flex w-full max-lg:hidden">
          <div className="border-r border-gray-500/50 flex flex-col px-[30px] py-[50px]">
            <img
              src="/assets/images/logo_header.svg"
              className="w-[100px]"
              alt="header-logo"
            />
            <div className="mt-auto text-[15px] tracking-[0.75px] text-[#565656]">
              ⓒ 2024. FitTech Dynamics. All Rights Reserved.
            </div>
          </div>
          <div className="border-r border-gray-500/50 min-w-[752px] flex flex-col">
            <div className="border-b border-gray-500/50 h-[151px] flex justify-between items-center px-20">
              <img
                src="/assets/images/logo_instagram.png"
                className="w-[116px] h-[18px] cursor-pointer hover:scale-125 duration-200"
                alt="logo_instagram"
                onClick={() => onClickLink('instagram')}
              />
              <div className="w-[1px] h-[30px] bg-gray-500/50"></div>
              <img
                src="/assets/images/logo_youtube.png"
                className="w-[90px] h-[18px] cursor-pointer hover:scale-125 duration-200"
                alt="logo_youtube"
                onClick={() => onClickLink('youtube')}
              />
              <div className="w-[1px] h-[30px] bg-gray-500/50"></div>
              <img
                src="/assets/images/logo_tictok.png"
                className="w-[67px] h-[18px] cursor-pointer hover:scale-125 duration-200"
                alt="logo_tictok"
                onClick={() => onClickLink('tiktok')}
              />
            </div>
            <img
              src="/assets/images/logo_fit_tech_dynamics.png"
              className="h-[83px] mt-[142px] px-20"
              alt="logo_fit_tech_dynamics"
            />
          </div>
          <div className="flex flex-col w-full">
            <div className="border-b border-gray-500/50 h-[151px] flex justify-end items-center">
              <div
                className="w-[153px] h-[153px] bg-deepBlue flex items-center justify-center cursor-pointer"
                onClick={moveTop}
              >
                <img
                  src="/assets/icons/icon_arrow_up.png"
                  className="h-[45px] "
                  alt="icon_arrow_up"
                />
              </div>
            </div>
            <div className="mt-auto mb-[50px] px-[60px] text-[15px] font-semibold tracking-[0.75px] text-[#565656] flex items-center justify-between">
              <span>서울특별시 강남구 테헤란로 152, 4층</span>
              <span>T 02-2006-6794 </span>
              <span> M info@fitdyn.com</span>
            </div>
          </div>
        </section>
        {/* mobile footer */}
        <section className="lg:hidden w-full flex flex-col">
          <div className="flex h-[108px] pl-5 pb-7 border-b border-gray-500/50">
            <img
              src="/assets/images/logo_header.svg"
              className="w-[100px] mt-[50px] "
              alt="header-logo"
            />
            <div
              className="ml-auto h-[108px] aspect-square bg-deepBlue flex items-center justify-center cursor-pointer"
              onClick={moveTop}
            >
              <img
                src="/assets/icons/icon_arrow_up.png"
                className="h-[45px] "
                alt="icon_arrow_up"
              />
            </div>
          </div>
          <div className="border-b border-gray-500/50 h-[80px] flex justify-center gap-x-10 items-center px-5 text-xs font-bold tracking-[-0.48px] text-center">
            <div
              className="cursor-pointer"
              onClick={() => onClickLink('instagram')}
            >
              INSTAGRAM
            </div>
            <div className="w-[1px] h-[30px] bg-gray-500/50"></div>
            <div
              className="cursor-pointer"
              onClick={() => onClickLink('youtube')}
            >
              YOUTUBE
            </div>
            <div className="w-[1px] h-[30px] bg-gray-500/50"></div>
            <div
              className="cursor-pointer"
              onClick={() => onClickLink('tiktok')}
            >
              TIKTOK
            </div>
          </div>
          <div className="flex flex-col pt-5 px-5 pb-10 text-xs text-[#565656] tracking-[-0.48px]">
            <span>서울특별시 강남구 테헤란로 152, 4층</span>
            <span className="mt-2.5">T 02-2006-6794 </span>
            <span className="mt-2.5"> M info@fitdyn.com</span>
            <img
              src="/assets/images/logo_fit_tech_dynamics.png"
              className="h-[20px] w-[129px] mt-[63px] "
              alt="logo_fit_tech_dynamics"
            />
            <span className="mt-2.5">
              ⓒ 2024. FitTech Dynamics. All Rights Reserved.
            </span>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
